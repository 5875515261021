import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select } from "antd";
import { AuthTokenType, DataProps, AddLocationFormProps, location } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { locationsUrl } from "../utils/network";

const { Option } = Select;

const AddLocationForm: FC<AddLocationFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingLocation,
  onCloseWithoutEditing, // new prop
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (editingLocation) {
      form.setFieldsValue(editingLocation);
    } else {
      form.resetFields();
    }
  }, [editingLocation, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.();
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingLocation) {
        // Editing location
        response = await axios.put(`${locationsUrl}/${editingLocation.id}/`, values, headers);
      } else {
        // Adding new location
        response = await axios.post(locationsUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  // Define an array of USA states
  const usaStates = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
    "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
    "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
    "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico",
    "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
    "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont",
    "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
  ];

  return (
    <Drawer
      title={editingLocation ? "Edit Location" : "Add Location"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Location Name"
          name="name"
          rules={[{ required: true, message: 'Please input the location name!' }]}
        >
          <Input placeholder="Bar One" />
        </Form.Item>
        <Form.Item
  label="Type"
  name="type"
  rules={[{ required: true, message: 'Please select the location type!' }]}
>
  <Select placeholder="Select location type">
    <Select.Option value="SubLocation">Sub-Location</Select.Option>
    <Select.Option value="Restaurant">Restaurant</Select.Option>
    <Select.Option value="Bar">Bar</Select.Option>
    <Select.Option value="Popup">Pop Up</Select.Option>
    <Select.Option value="Lounge">Lounge</Select.Option>
    <Select.Option value="Hall">Hall</Select.Option>
    <Select.Option value="Storage">Storage</Select.Option>
  </Select>
</Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please input the location address!' }]}
        >
          <Input placeholder="1447 N. Summit St." />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input the location city!' }]}
        >
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please select the location state!' }]}
        >
          <Select placeholder="Select a state">
            {usaStates.map(state => (
              <Option key={state} value={state}>
                {state}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input the location country!' }]}
        >
          <Input placeholder="Location Country" defaultValue="USA" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingLocation ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddLocationForm;