import { Drawer, Form, Input, Select, Button, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AddOnHandInventoryFormProps, AuthTokenType, DataProps, location, product } from '../utils/types';
import { getAuthToken } from '../utils/functions';
import axios, { AxiosResponse } from 'axios';
import { onhandUrl } from '../utils/network';
import { useGetLocation, useGetProduct } from '../utils/hooks';

const { Option } = Select;

const AddOnHandInventoryForm: FC<AddOnHandInventoryFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  onCloseWithoutEditing,
  editingInventory,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<product[]>([]);
  const [locations, setLocations] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [showReason, setShowReason] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(''); // New state for reason

  useEffect(() => {
    if (editingInventory) {
      form.setFieldsValue({
        ...editingInventory,
        sku_code_id: editingInventory.sku_code.id,
        storage_location_id: editingInventory.storage_location.id,
      });
      setShowReason(true);
    } else {
      form.resetFields();
      setShowReason(false);
    }
  }, [editingInventory, form]);

  useGetProduct(setProducts, setFetching);
  useGetLocation(setLocations, setFetching);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;
    try {
      let response: AxiosResponse;
      if (editingInventory) {
        if (showReason) {
          values.reason = reason; // Include the reason from state
        }
        response = await axios.put(`${onhandUrl}/${editingInventory.id}`, values, headers);
      } else {
        if (showReason) {
          values.reason = reason; // Include the reason from state
        }
        response = await axios.post(onhandUrl, values, headers);
      }
      setLoading(false);
      if (response) {
        form.resetFields(); // Reset form fields on successful API call
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: 'Operation Error',
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const handleReasonChange = (value: string) => {
    setReason(value); // Update the reason state when the user selects a reason
  };

  return (
    <Drawer
      title="Add On Hand Inventory"
      visible={isVisible}
      placement="right"
      onClose={handleFormClose}
      footer={null}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label="Product" name="sku_code_id" rules={[{ required: true, message: 'Please select a product' }]}>
          <Select disabled={!!editingInventory}>
            <Option value="" disabled>Select Product</Option>
            {products.map(product => (
              <Option key={product.id} value={product.id}>
                {product.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Location" name="storage_location_id" rules={[{ required: true, message: 'Please select a location' }]}>
          <Select disabled={!!editingInventory}>
            <Option value="" disabled>Select Storage Location</Option>
            {locations.map(location => (
              <Option key={location.id} value={location.id}>
                {location.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Status" name="status" rules={[{ required: true, message: 'Please select a status' }]}>
          <Select defaultValue="" style={{ width: "100%" }} disabled={!!editingInventory}>
            <Option value="">Select Status</Option>
            <Option value="InStock">In Stock</Option>
            <Option value="Pending">Pending</Option>
          </Select>
        </Form.Item>

        {showReason && (
          <Form.Item label="Reason" name="reason" rules={[{ required: true, message: 'Please select a reason' }]}>
            <Select defaultValue="" style={{ width: "100%" }} placeholder="Select Reason" onChange={handleReasonChange}>
              <Option value="Stock Received">Stock Received</Option>
              <Option value="Inventory Recount">Inventory Recount</Option>
              <Option value="Damage">Damage</Option>
              <Option value="Theft">Theft</Option>
              <Option value="Loss">Loss</Option>
              <Option value="Restock Return">Restock Return</Option>
            </Select>
          </Form.Item>
        )}

        {!editingInventory ? (
          <Form.Item
            label="Total Units"
            name="totalunits"
            rules={[{ required: true, message: 'Please input total units' }]}
          >
            <Input placeholder="Total Units" type="number" />
          </Form.Item>
        ) : (
          <Form.Item
            label="Changed Units"
            name="changedunits"
            rules={[{ required: true, message: 'Please input changed units' }]}
          >
            <Input placeholder="Changed Units" type="number" />
          </Form.Item>
        )}

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddOnHandInventoryForm;