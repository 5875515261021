import { BrowserRouter, Route, Routes  } from 'react-router-dom';
import Login from "./pages/Login"
import CheckUser from "./pages/CheckUser"
import Home from "./pages/Home"
import AuthRoute from './components/AuthRoute';
import User from './pages/User';
import UpdateUserPassword from './pages/UpdateUserPassword';
import Categories from './pages/Categories';
import Unitofmeasure from './pages/Unitofmeasure';
import Locations from './pages/Locations';
import Products from './pages/Products';
import OnHandInventory from './pages/OnHandInventory';
import ResetPassword from './pages/ResetPassword';
import ForgetPassword from './pages/ForgotPassword';
import Suppliers from './pages/Suppliers';
import PurchaseOrder from './pages/AddPurchase';
import PurchaseOrderReview from './pages/PurchaseOrderReview';


function Router() {
  return (
  <BrowserRouter>
      <Routes>
      
      <Route path="/Login"  element={<Login/>}/>
      <Route path="/check-user"  element={<CheckUser/>}/>
      <Route path="/create-password"  element={<UpdateUserPassword/>}/>
      <Route path="/forgotpassword" element={<ForgetPassword/>}/>
      <Route path="/resetpassword" element={<ResetPassword/>}/>


      <Route path="/" element={<AuthRoute><Home/></AuthRoute>}/>
      <Route path="/users" element={<AuthRoute><User/></AuthRoute>}/>
      <Route path="/category" element={<AuthRoute><Categories/></AuthRoute>}/>
      <Route path="/uom" element={<AuthRoute><Unitofmeasure/></AuthRoute>}/>
      <Route path="/location" element={<AuthRoute><Locations/></AuthRoute>}/>
      <Route path="/product" element={<AuthRoute><Products/></AuthRoute>}/>
      <Route path="/supplier" element={<AuthRoute><Suppliers/></AuthRoute>}/>
      <Route path="/onhand" element={<AuthRoute><OnHandInventory/></AuthRoute>}/>
      <Route path="/purchase" element={<AuthRoute><PurchaseOrder/></AuthRoute>}/>
      <Route path="/purchasereview" element={<AuthRoute><PurchaseOrderReview/></AuthRoute>}/>

   

      </Routes>
  </BrowserRouter>
  )
}
export default Router