import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button } from "antd";
import { AuthTokenType, DataProps, AddSupplierFormProps, supplier } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { suppliersUrl } from "../utils/network";

const AddSupplierForm: FC<AddSupplierFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingSupplier,
  onCloseWithoutEditing, // new prop
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editingSupplier) {
      form.setFieldsValue(editingSupplier);
    } else {
      form.resetFields();
    }
  }, [editingSupplier, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingSupplier) {
        // Editing supplier
        response = await axios.put(`${suppliersUrl}/${editingSupplier.id}/`, values, headers);
      } else {
        // Adding new supplier
        response = await axios.post(suppliersUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: ("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  return (
    <Drawer
      title={editingSupplier ? ("Edit Supplier") : ("Add Supplier")} // Translate the title
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          label={("Name")}
          name="name"
          rules={[{ required: true, message: ("Please input the supplier name!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={("Email")}
          name="email"
          rules={[{ required: true, message: ("Please input the supplier email!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={("Address")}
          name="address"
          rules={[{ required: true, message: ("Please input the supplier address!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={("City")}
          name="city"
          rules={[{ required: true, message: ("Please input the supplier city!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={("State")}
          name="state"
          rules={[{ required: true, message: ("Please input the supplier state!") }]}
        >
          <Input />
        </Form.Item>

         <Form.Item
          label={("Country")}
          name="country"
          rules={[{ required: true, message: ("Please input the supplier country!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={("Phone")}
          name="phone"
          rules={[{ required: true, message: ("Please input the supplier phone number!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={("Type")}
          name="type"
          rules={[{ required: true, message: ("Please input the supplier type!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingSupplier ? ("Update") : ("Submit")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddSupplierForm;