import { useContext } from "react";
import { store } from "../utils/store";
import InventoryTable from "./InventoryTable";

function Home(){
  const{state}=useContext(store)
  return (
      <div>
          <div>
          </div>
              <br/>
              <div >
                <InventoryTable/>
              </div>
              <br/>
          </div>
  )
}
export default Home