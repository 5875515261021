import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Select, Tooltip, Tag } from "antd";
import { useGetProductwithinventory, useGetproductprice, } from "../utils/hooks";
import { AuthTokenType, product, } from "../utils/types";
import { getAuthToken, getProductwithinventory, getproductprice } from "../utils/functions";
import AddProductForm from "../components/AddProductForm";
import axios from "axios";
import { productsUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";

const Products: FC = () => {
  const { Option } = Select;
  const [products, setProducts] = useState<product[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [formVisible, setFormVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState<product | null>(null);
  const [expandedImage, setExpandedImage] = useState<string | undefined>(undefined); // State for expanded image

  useGetProductwithinventory(setProducts, setFetching);

  const [productPriceVisible, setProductPriceVisible] = useState<boolean>(false);
  const [productPrices, setProductPrices] = useState<any[]>([]);
  const [priceFetching, setPriceFetching] = useState<boolean>(false);





  useEffect(() => {
    if (editingProduct) {
      setFormVisible(true);
    }
  }, [editingProduct]);

  const handleEdit = (record: product) => {
    setEditingProduct(record);
  };

  const onCloseWithoutEditing = () => {
    setEditingProduct(null);
  };

  const handleProductIdClick = (productId: number) => {
    
    setProductPriceVisible(true);
    getproductprice(setProductPrices, setPriceFetching, productId);
  };


  const handleDelete = (productId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this product?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${productsUrl}/${productId}/`, headers);
          getProductwithinventory(setProducts, setFetching);
        } catch (error) {
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  // Function to handle image click and expand
  const handleImageExpand = (imageUrl: string) => {
    setExpandedImage(imageUrl);
  };

  const columns = [
    {
      title: ("Image"),
      dataIndex: "image",
      key: "image",
      render: (image: string) => (
        image ? <img src={image} alt="Product" style={{ width: "4vw", height: "4vh", cursor: "pointer" }} onClick={() => handleImageExpand(image)} /> : ("No Image Available")
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: number) => (
        <a onClick={() => handleProductIdClick(id)}>{id}</a>
      ),
    },
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "SKU UOM",
      dataIndex: "uom",
      key: "uom",
    },
    {
      title: "Category",
      dataIndex: "cat",
      key: "cat",
    },
    {
      title: "Sub Cat",
      dataIndex: "subcat",
      key: "subcat",
    },
    {
      title: (
        <Tooltip title="Cost Per Whole Unit">
          <span>Cost Per WU</span>
        </Tooltip>
      ),
      dataIndex: "costperwholeunit",
      key: "costperwholeunit",
    },
    {
      title: (
        <Tooltip title="Sub Unit Sale Price">
          <span>SU Sale Price</span>
        </Tooltip>
      ),
      dataIndex: "subunitsaleprice",
      key: "costperwholeunit",
    },
//    {
//      title: (
//        <Tooltip title="Qty of Sub Units Per Whole Unit">
//          <span>SU Per WU</span>
//        </Tooltip>
//      ),
//      dataIndex: "subunitsperwholeunit",
//      key: "subunitsperwholeunit",
//    },
    {
      title: "Revenue Per WU",
      dataIndex: "revenueperwholeunit",
      key: "revenueperwholeunit",
    },
    {
      title: "$ Mrgn Per WU",
      dataIndex: "marginperwholeunit",
      key: "marginperwholeunit",
    },
    {
      title: "% Mrgn Per WU",
      dataIndex: "marginperwholeunitpercent",
      key: "marginperwholeunitpercent",
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
    },
    {
      title: "Last Count Date",
      dataIndex: "last_count_date1",
      key: "last_count_date1",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: product) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  return (
    <ContentLayout
      pageTitle="Products"
      buttontitle="Add Product"
      setModalState={setFormVisible}
      dataSource={products as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
      {/* Image Modal */}
      <Modal
        visible={!!expandedImage}
        onCancel={() => setExpandedImage(undefined)}
        footer={null}
        closable={false}
      >
        <img src={expandedImage || ''} alt="Expanded" style={{ width: "100%" }} />
      </Modal>
      <Modal
        visible={productPriceVisible}
        onCancel={() => {
          setProductPriceVisible(false);
          setProductPrices([]); // Reset productPrices state to null
        }}
        footer={null}
      >
        {priceFetching ? (
          <p>Loading...</p>
        ) : (
          <Table
            className="custom-table"
            dataSource={productPrices}
            columns={[
              {
                title: "Date",
                dataIndex: "date",
                key: "date",
                render: (dateString: string) => {
                  const date = new Date(dateString);
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const day = String(date.getDate()).padStart(2, '0');
                  return `${year}-${month}-${day}`;
                },
              },
              {
                title: "Type",
                dataIndex: "type",
                key: "type",
              },

              {
                title: "Value",
                dataIndex: "value",
                key: "value",
              },
              {
                title: "Previous Value",
                dataIndex: "previous_value",
                key: "previous_value",
              },
              {
                title: "Difference",
                dataIndex: "difference",
                key: "difference",
              },

            ]}
            rowKey="id"
          />
        )}
      </Modal>
      <AddProductForm
        onSuccessCallBack={() => {
          setFormVisible(false);
          getProductwithinventory(setProducts, setFetching);
        }}
        isVisible={formVisible}
        onClose={() => setFormVisible(false)}
        editingProduct={editingProduct}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Products;