import axios,{AxiosResponse} from "axios"
import {tokenName} from "./data"
import {MeUrl, SankeyChartURL,UsersUrl,categoriesUrl,locationsUrl, onhandUrl, productinventoryViewSetURL, productpriceViewSetURL, productsUrl,productswithinventoryUrl,purchaseorderUrl,suppliersUrl,unitofmeasuresUrl} from "./network"
import { AuthTokenType,OnhandInventoryProps,UserType, category, product, purchaseProps} from "./types"
import { notification } from "antd"

import moment from "moment"

export const getAuthToken= ():AuthTokenType|null=>{
    const accessToken =localStorage.getItem(tokenName)
    if(!accessToken){
        return null
    }

    return {headers:{Authorization:`Bearer ${accessToken}`}}

}

export const logout =()=>{
    localStorage.removeItem(tokenName)
    window.location.href="/login"

}

export const authHandler=async ():Promise<UserType | null>=>{
    const headers=getAuthToken()
        if(!headers){
            return null
    }
    const response:AxiosResponse =await axios.get(MeUrl,headers).catch(
        (e)=>{}
    ) as AxiosResponse
    if(response){
        return response.data as UserType
    }
    return null

}


export const getUsers = async (
  setUsers: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(UsersUrl, headers);
    
    setUsers(response.data);
  } finally {
    setFetching(false);
  }
};






export const getCategory = async (
  setCategory: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(categoriesUrl, headers);

    const data=(response.data as category[]).map((item) => ({...item,
      par:item.parent?.name
    
    }))
    
    setCategory(data);
  } finally {
    setFetching(false);
  }
};

export const getUnitofmeasure = async (
  setUnitofmeasure: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(unitofmeasuresUrl, headers);
    setUnitofmeasure(response.data);
  } finally {
    setFetching(false);
  }
};

export const getLocation = async (
  setLocation: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(locationsUrl, headers);
    setLocation(response.data);
  } finally {
    setFetching(false);
  }
};


export const getProduct = async (
  setProduct: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(productsUrl, headers);
    const data=(response.data as product[]).map((item) => ({...item,
      uom:item.sku_uom.name,
      cat:item.category.parent?.name,
      subcat:item.category.name
  
    }))

    setProduct(data);
  } finally {
    setFetching(false);
  }
};


export const getProductwithinventory = async (
  setProduct: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(productswithinventoryUrl, headers);
    const data=(response.data as product[]).map((item) => ({...item,
      uom:item.sku_uom.name,
      cat:item.category.parent?.name,
      subcat:item.category.name,
      last_count_date1:moment(item.last_count_date).format('YYYY-MM-DD'),
    }))

    setProduct(data);
  } finally {
    setFetching(false);
  }
};


export const getOnhandInventory=async (setOnhandInventory:(data:OnhandInventoryProps[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(onhandUrl,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<OnhandInventoryProps>
  const data=(response.data as OnhandInventoryProps[]).map((item) => ({...item,
    created_at:moment(item.created_at).format('DD-MM-YYYY'),
    code:item.sku_code.code,
    name:item.sku_code.name,
    productid:item.sku_code.id,
    locationid:item.storage_location.id,
    cat:item.sku_code.category.parent.name,
    subcat:item.sku_code.category.name,
    storage:item.storage_location.name,
  }))
  setOnhandInventory(data) 
  setFetching(false)

}



export const getSankey = async (
  setSankey: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(SankeyChartURL, headers);
    setSankey(response.data);
  } finally {
    setFetching(false);
  }
};

export const getproductprice = async (
  setproductprice: (data: any) => void,
  setFetching: (val: boolean) => void,
  product_id:any
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);
  const queryParams = {
    product_id: product_id,
  };


  try {
    const response: AxiosResponse = await axios.get(productpriceViewSetURL, {
      ...headers,
         params:queryParams,});
    setproductprice(response.data);
  } finally {
    setFetching(false);
  }
};


export const getproductinventory = async (
  setproductinventory: (data: any) => void,
  setFetching: (val: boolean) => void,
  product_id:any,location_id:any
) => {
  const headers = getAuthToken() as AuthTokenType;
   
  setFetching(true);
  const queryParams = {
    product_id: product_id,
    location_id:location_id  
  };
  try {
    const response: AxiosResponse = await axios.get(productinventoryViewSetURL, {
      ...headers,
         params:queryParams,});
    setproductinventory(response.data);
  } finally {
    setFetching(false);
  }
};


export const getSupplier = async (
  setSupplier: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(suppliersUrl, headers);
    setSupplier(response.data);
  } finally {
    setFetching(false);
  }
};


export const getpurchase=async (setpurchase:(data:purchaseProps[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(purchaseorderUrl,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<purchaseProps>
if(response){
  const data=(response.data as purchaseProps[]).map((item) => ({...item,
    expected_delivery_date:moment(item.expected_delivery_date).format('DD-MM-YYYY'),
    actual_delivery_date:moment(item.actual_delivery_date).format('DD-MM-YYYY'),
    departure_date:moment(item.departure_date).format('DD-MM-YYYY'),
    origin:item.from_location?.name,
    destination:item.to_location?.name
  }))
  setpurchase(data) 
  setFetching(false)

}

}
