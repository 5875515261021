import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Select, Button, DatePicker, InputNumber, Switch } from "antd";
import { AuthTokenType, DataProps, AddProductFormProps, unitofmeasure, category,} from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { productsUrl } from "../utils/network";
import { useGetCategory,useGetUnitofmeasure } from "../utils/hooks";
import { useRef } from 'react';
import dayjs from "dayjs"; // Import dayjs
import { BaseUrl } from '../utils/network';

const ImageHolder: string = require("../assets/ImageHolder.svg").default;

const AddProductForm: FC<AddProductFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingProduct,
  onCloseWithoutEditing, // new prop
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<category[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [imageloading, setImageLoading] = useState(false);
  const fileSelect = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useGetCategory(setCategories, setFetching);



  const [unitofmeasures, setUnitofmeasures] = useState<unitofmeasure[]>([]);

  useGetUnitofmeasure(setUnitofmeasures, setFetching);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    // Populate form fields if editing a product
    if (editingProduct) {
      form.setFieldsValue({
        ...editingProduct,
        sku_uom_id: editingProduct.sku_uom.id,
        category_id: editingProduct.category.id,
        last_count_date: editingProduct.last_count_date ? dayjs(editingProduct.last_count_date) : null,

      });
      // Set the image URL if the product has an image
    } else {
      form.resetFields();
      setImageUrl(null); // Reset the image URL when adding a new product
    }
  }, [editingProduct, form]);


  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const selectedImage = fileList[0];
      setImage(selectedImage); // Update state with the selected file

      // Create a URL object for the selected image and set it as the background
      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let formData = new FormData(); // Create a new FormData object

      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null && key !== 'image') {
          // Check if the value is a valid date
          if (key === 'last_count_date') {
            // Convert string representing boolean to boolean value
            if (typeof value === 'string' && (value === 'true' || value === 'false')) {
              value = value === 'true';
            }
            // Check if value is a valid date
            if (!isNaN(Date.parse(value.toString()))) {
              // Format the date to ISO 8601 format
              value = dayjs(value.toString()).format('YYYY-MM-DD');
            }
          }
          formData.append(key, value.toString());
        }
      });
  
      if (image) {
        formData.append('image', image);
      }
      // Append the image file to the FormData object
      
      let response: AxiosResponse;

      if (editingProduct) {
        // Editing product
        response = await axios.put(`${productsUrl}/${editingProduct.id}/`, formData, headers);
      } else {
        // Adding new product
        response = await axios.post(productsUrl + '/', formData, headers);
      }
      setLoading(false);
      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: 'Operation Error',
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingProduct ? "Edit Product" : "Add Product"}
      visible={isVisible}
      onClose={handleFormClose}

      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
                <Form.Item
          label={("Product Photo")}
          name="image"
        >
          <div
            className="imageview"
            onClick={() => !loading && fileSelect.current?.click()}
            style={{
              backgroundImage: `url(${imageUrl ? imageUrl : ImageHolder})`
            }}
          />
          <input type="file" style={{ display: "none" }} ref={fileSelect} onChange={handleFileChange} />
        </Form.Item>

<Form.Item
  label="ID"
  name="id"
  rules={[
    { required: true, message: 'Please input the product ID!' },
    { max: 16, message: 'ID must be at most 16 characters long!' },
    { pattern: /^[a-zA-Z0-9-]+$/, message: 'ID must contain only alphanumeric characters and hyphens!' }  ]}
>
  <Input />
</Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the product name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Is Active" name="is_active" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item
          label="SKU UOM"
          name="sku_uom_id"
          rules={[{ required: true, message: 'Please select the SKU UOM!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a unit of measure</Select.Option>
            {unitofmeasures.map(uom => (
              <Select.Option key={uom.id} value={uom.id}>
                {uom.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
    
        <Form.Item
          label="Category"
          name="category_id"
          rules={[{ required: true, message: 'Please select the category!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a category</Select.Option>
            {categories.map(category => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label="Cost Per Whole Unit"
          name="costperwholeunit"
          rules={[{ required: true, message: 'Please input the Cost Per Whole Units!' }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Sub Unit Sale Price"
          name="subunitsaleprice"
          rules={[{ required: true, message: 'Please input the Sub Unit Sale Price!' }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Sub Units Per Unit"
          name="subunitsperwholeunit"
          rules={[{ required: true, message: 'Please input the Sub Units Per Unit!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
        label={("Last Count Date")}
        name="last_count_date"
        rules={[{ required: true, message: ('Please input the Original Date!') }]}
      >
          <DatePicker onChange={(date:any) => form.setFieldsValue({ last_count_date: date ? date : null })} />
      </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingProduct ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddProductForm;