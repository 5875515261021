import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { FaRegCalendarAlt } from "react-icons/fa";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ThreePIcon from '@mui/icons-material/ThreeP';
import ScaleIcon from '@mui/icons-material/Scale';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PersonIcon from '@mui/icons-material/Person';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import ReorderIcon from '@mui/icons-material/Reorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LabelIcon from '@mui/icons-material/Label';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StyleIcon from '@mui/icons-material/Style';
import SellIcon from '@mui/icons-material/Sell';
import { Tooltip } from 'antd';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { logout } from '../utils/functions';
import { saveAs } from 'file-saver';
import logo from '../assets/logo.png'; // Import the logo image

const MysideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  

  // Assuming you have the user's role stored in localStorage
  const userRole = localStorage.getItem('token1');
  const fullname = localStorage.getItem('token3');



  return (
    <div style={{ display: "flex", height: "100vh"}}
  >

 
      <Sidebar className="MysideBar" collapsed={collapsed}  >
      <div className="logo-containersb">
          <img src={logo} alt="logo" className="logosb" />
        </div>
        
        <Menu >

          <MenuItem className="menu1" icon={<MenuRoundedIcon />}>
          <h3><b>{fullname}</b></h3>
          </MenuItem>
          <hr  /> 
            <MenuItem className={location.pathname === "/" ? "activeMenuItem" : ""} icon={<DashboardIcon style={{ color: location.pathname === "/" ? "#505050" : "grey" }} />} component={<Link to="/" />}> Dashboard</MenuItem>
            <SubMenu label="Master Data" icon={<AllInclusiveIcon style={{ color: "grey" }} />}>
              <MenuItem className={location.pathname === "/category" ? "activeMenuItem" : ""} icon={<LabelIcon style={{ color: location.pathname ==="/category" ? "#505050" : "grey" }} />} component={<Link to="/category" />}>Category</MenuItem>
              <MenuItem className={location.pathname === "/uom" ? "activeMenuItem" : ""} icon={<ScaleIcon style={{ color: location.pathname ==="/uom" ? "#505050" : "grey" }} />} component={<Link to="/uom" />}>Unit of Measure</MenuItem>
              <MenuItem className={location.pathname === "/location" ? "activeMenuItem" : ""} icon={<MapIcon style={{ color: location.pathname ==="/location" ? "#505050" : "grey" }} />} component={<Link to="/location" />}>Locations</MenuItem>
              <MenuItem className={location.pathname === "/product" ? "activeMenuItem" : ""} icon={<CategoryIcon style={{ color: location.pathname ==="/product" ? "#505050" : "grey" }} />} component={<Link to="/product" />}>Products</MenuItem>
              <MenuItem className={location.pathname === "/supplier" ? "activeMenuItem" : ""} icon={<LocalShippingIcon style={{ color: location.pathname ==="/supplier" ? "grey" : "#a9a9a9" }} />} component={<Link to="/supplier" />}>Suppliers</MenuItem>
            </SubMenu>
            <SubMenu label={("Buy")} icon={<ShoppingCartIcon style={{ color: "#a9a9a9" }} />}>
              <MenuItem className={location.pathname === "/purchase" ? "activeMenuItem" : ""} icon={<ReorderIcon style={{ color: location.pathname ==="/purchase" ? "grey" : "#a9a9a9" }} />} component={<Link to="/purchase" />}>Create Order</MenuItem>
              <MenuItem className={location.pathname === "/purchasereview" ? "activeMenuItem" : ""} icon={<BackupTableIcon style={{ color: location.pathname ==="/purchasereview" ? "grey" : "#a9a9a9" }} />} component={<Link to="/purchasereview" />}>Order Review</MenuItem>

           
            </SubMenu>

            <MenuItem className={location.pathname === "/onhand" ? "activeMenuItem" : ""} icon={<InventoryIcon style={{ color: location.pathname === "/onhand" ? "#505050" : "grey" }} />} component={<Link to="/onhand" />}> Inventory</MenuItem>
            <MenuItem className={location.pathname === "/users" ? "activeMenuItem" : ""} icon={<PersonIcon style={{ color: location.pathname === "/users" ? "#505050" : "grey" }} />} component={<Link to="/users" />}> Users</MenuItem>
            <MenuItem onClick={logout} icon={<LogoutOutlinedIcon style={{ color: location.pathname === "/logout" ? "#505050" : "#757575" }} />}> Logout</MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default MysideBar;