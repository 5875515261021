import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker, Switch } from "antd";
import { AuthTokenType, EditPurchaseOrderProps, purchaseProps } from "../utils/types";
import axios, { AxiosResponse } from "axios";
import { purchaseorderUrl } from "../utils/network";
import { getAuthToken } from "../utils/functions";

const EditPurchaseOrderDrawer: FC<EditPurchaseOrderProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingPurchase,
  onCloseWithoutEditing, // new prop
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (editingPurchase) {
      form.setFieldsValue(editingPurchase);
    } else {
      form.resetFields();
    }
  }, [editingPurchase, form]);

  const handleSubmit = async (values: Partial<purchaseProps>) => {
    setLoading(true);
    try {
      let response: AxiosResponse;
      setLoading(true);

      const headers = getAuthToken() as AuthTokenType;

      // Constructing the URL
      response = await axios.patch(purchaseorderUrl+'/'+editingPurchase?.id, values, headers);

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose();
        notification.success({
          message: ("Edit Successful"),
          description: ("Changes have been saved successfully."),
        });
      }
    } catch (error) {
      notification.error({
        message: ("Operation Error"),
      });
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    onClose();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.();
    }
  };

  return (
    <Drawer
      title={("Edit Purchase Order")}
      visible={isVisible}
      onClose={handleDrawerClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
        <Form.Item label={("ID")} name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item label={("PO Number")} name="po_number">
          <Input />
        </Form.Item>
        <Form.Item label={("Shipping Company")} name="shipping_company">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditPurchaseOrderDrawer;