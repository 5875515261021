import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { DataProps } from '../utils/types';
import logo from '../assets/logo.png';

interface AuthComponentProps {
  titleText?: string;
  isPassword?: boolean;
  bottonText?: string;
  linkText?: string;
  linkPath?: string;
  onSubmit: (value: DataProps) => void;
  loading?: boolean;
  isUpdatePassword?: boolean;
}

function AuthComponent({
  titleText = 'Sign in',
  isPassword = true,
  bottonText = 'Login',
  linkText = 'Forgot Password?',
  linkPath = '/forgotpassword',
  onSubmit,
  loading = false,
  isUpdatePassword = false,
}: AuthComponentProps) {
  return (
    <div className="login-container">
      <div className="login-content">
        <div className="form-container">
        <div className="logo-container" style={{ textAlign: 'center' }}>
          <img src={logo} alt="logo" className="logo" />
        </div>
 
          <Form layout="vertical" onFinish={onSubmit}>
            {!isUpdatePassword && (
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>
            )}
            {isPassword && (
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            )}
            {isUpdatePassword && (
              <Form.Item
                name="cpassword"
                rules={[{ required: true, message: 'Please input your password confirmation!' }]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            )}
            <Form.Item>
              <Button htmlType="submit" type="primary" block loading={loading} style={{ backgroundColor: 'black', borderColor: 'black' }}>
                {bottonText}
              </Button>
            </Form.Item>
          </Form>
          <Link to={linkPath}>{linkText}</Link>
        </div>
      </div>
    </div>
  );
}

export default AuthComponent;