import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag } from "antd";
import { useGetUnitofmeasure } from "../utils/hooks";
import { AuthTokenType, unitofmeasure } from "../utils/types";
import { getAuthToken, getUnitofmeasure } from "../utils/functions";
import axios from "axios";
import { unitofmeasuresUrl } from "../utils/network";
import AddUnitofmeasureForm from "../components/AddUnitofmeasureForm";
import ContentLayout from "../components/ContentLayout";

const Unitofmeasure: FC = () => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },


    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: unitofmeasure) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [unitofmeasures, setUnitofmeasures] = useState<unitofmeasure[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingUnitofmeasure, setEditingUnitofmeasure] = useState<unitofmeasure | null>(null);

  useGetUnitofmeasure(setUnitofmeasures, setFetching);

  useEffect(() => {
    // When editingUnitofmeasure changes, open the modal
    if (editingUnitofmeasure) {
      setDrawerVisible(true);
    }
  }, [editingUnitofmeasure]);

  const handleEdit = (record: unitofmeasure) => {
    setEditingUnitofmeasure(record);
  };

  const handleDelete = (unitofmeasureId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this unit of measure?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a unit of measure
          await axios.delete(`${unitofmeasuresUrl}/${unitofmeasureId}/`, headers);

          // After successful deletion, fetch updated unit of measures
          getUnitofmeasure(setUnitofmeasures, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };
  const onCloseWithoutEditing = () => {
    setEditingUnitofmeasure(null); // Reset editingCategory when form is closed without editing
  };


  const onCreateUnitofmeasure = () => {
    setDrawerVisible(true);
    setEditingUnitofmeasure(null); // Reset editingUnitofmeasure for new unit of measure
  };

  return (
    <ContentLayout
      pageTitle="Unit of Measures"
      buttontitle="Add Unit of Measure"
      setModalState={setDrawerVisible}
      dataSource={unitofmeasures as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
       >
      <AddUnitofmeasureForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getUnitofmeasure(setUnitofmeasures, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingUnitofmeasure={editingUnitofmeasure}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Unitofmeasure;